import Cookies from 'js-cookie'

export const setLang = (name) => {
  Cookies.set('locale', name, { expires:  7 })
}
export const getLang = () => {
  const locale = Cookies.get('locale')  
  if (locale) return locale
  else return false
}
 // 除以100保留两位小数点
 export const divide=(str,type)=> {
    let floatVal = parseFloat(str);
    if (isNaN(floatVal )) {
      return 0;
    }
    floatVal = Math.round(str * 100) / 10000;
    let strVal = floatVal .toString();
    let searchVal = strVal.indexOf('.');
    if (searchVal < 0) {
      searchVal = strVal.length;
      strVal += '.';
    }
    while (strVal.length <= searchVal + 2) {
      strVal += '0';
    }
    
    return strVal
    // if(type == 'float'){
    //   return parseFloat(strVal);
    // }else {
    //   return strVal
    // }
  }
  // 乘以100保留两位小数点
  export const multiply=(str) =>{
    let floatVal = parseFloat(str);
    if (isNaN(floatVal)) {
      return 0;
    }
    floatVal = Math.round(str * 10000) / 100;
    let strVal = floatVal.toString();
    let searchVal = strVal.indexOf('.');
    if (searchVal < 0) {
      searchVal = strVal.length;
      strVal += '.';
    }
    while (strVal.length <= searchVal + 2) {
      strVal += '0';
    }
 
    return parseFloat(strVal);
  }
//   防抖 300毫秒内多次点击只生效一次
  export const debounce = function (func, delay = 300) {
    let context = this 
    let args = arguments;
    return function () {
      if (context.timeout) {
        clearTimeout(context.timeout);
      }
      context.timeout = setTimeout(() => {
        func.apply(context, args);
      }, delay);
    };
  };
//   节流 
  export const throttle = function(func,delay = 300){
    let context = this
    let args = arguments;
    return function(){
      if (context.timeout) {
        return
      }
      context.timeout = setTimeout(() => {
        func.apply(context, args);
        context.timeout = null;
      },delay)
    }
  }
  